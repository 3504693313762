import type React from 'react'
import { useEffect, useRef } from 'react'
import { useCurrentUrlInfo } from 'hooks/useCurrentUrlInfo'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react'
import { readCouponDetailsFromUrl } from 'mobx/CouponFlow/utils'
import { CONSTANTS } from 'utils/constants'

const UrlActionHandler: React.FC = () => {
	const { pathname, query, hash, removeHash } = useCurrentUrlInfo()

	const handledHashes = useRef<Set<string>>(new Set())

	const { couponFlowStore } = useStores()

	const handleExternalCouponFromUrl = async () => {
		if (couponFlowStore.couponToApply || !hash || (hash && handledHashes.current.has(hash))) {
			return
		}

		const couponDetails = readCouponDetailsFromUrl({ query, hash })

		if (couponDetails) {
			handledHashes.current.add(hash)
			removeHash()
			console.log('couponFlowStore.start', couponDetails)
			await couponFlowStore.start({
				code: couponDetails.code,
				orderTypeToForce: couponDetails.orderTypeToForce,
				openModal: true,
			})
		}
	}

	useEffect(() => {
		const handleRouteChange = async () => {
			if (hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.ITEM) || hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.DISCOUNT)) {
				await handleExternalCouponFromUrl()
			}
			// handleRefFromUrl()
		}

		handleRouteChange()
	}, [pathname, query, hash])

	return null // This component does not render anything
}

export default observer(UrlActionHandler)
