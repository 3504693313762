import { staticImplements } from 'utils/types'
import type {
	ServerSideCoupon,
	CouponsDependencies,
	PrbResponse,
	SearchBody,
	AddCouponToWalletResponse,
	GetCouponQuery,
	PrbCoupon,
	Feature,
} from './types'
import { getGT, sendRequest } from 'utils/utils'
import Infra from 'mobx/Infra'
import type { AxiosError, Method } from 'axios'
import Account from 'mobx/Account'
import CouponError, { ErrorCode } from './errors'
import type { Flags } from 'types/Coupons'
import { OrderType, type StrippedCoupon } from 'types/Coupons'
import { CheckCouponOn } from '../CouponFlow'
import AddressManager from '../AddressManager'
import Cart from 'mobx/Cart'
import type GetGTResponse from 'types/GetGTResponse'
import type { Menu } from 'types/Menu'
import { getDateFormatByLocale } from 'utils/timeUtils'

@staticImplements<CouponsDependencies>()
export default class CouponsRepository {
	/**
	 * Sends a request to the Coupons API (Marketing Service) with the specified parameters
	 *
	 * @throws CouponError if the request fails. Attempts to extract the message and code from the response, but will default to error.message and ErrorCode.DEFAULT
	 *
	 */

	private static async fetch<T = void>(
		url: string,
		method: Method,
		body?: object,
		queryParams?: string | string[][] | Record<string, string> | URLSearchParams
	): Promise<T> {
		const baseUrl = `${Infra.appParams.wruec}/v1/tenants/${Infra.appParams.c}/`

		let fullUrl = `${baseUrl}${url}`

		if (queryParams) {
			const params = new URLSearchParams(queryParams)
			fullUrl += `?${params.toString()}`
		}

		return (await sendRequest(false, fullUrl, method, body, await Account.getAuthorizationHeader(false)).catch(
			(e: AxiosError<{ error?: { message?: string; code?: ErrorCode } }>) => {
				const { error } = e.response?.data ?? {}
				const message = error?.message ?? e.message
				throw new CouponError(message, error?.code ?? ErrorCode.DEFAULT)
			}
		)) as T
	}

	private static isValidCoupon(maybeCoupon: unknown): maybeCoupon is ServerSideCoupon {
		return maybeCoupon != null && typeof maybeCoupon === 'object' && 'id' in maybeCoupon && 'features' in maybeCoupon
	}

	private static normaliseCoupon(coupon: ServerSideCoupon): StrippedCoupon {
		const { features, ...rest } = coupon

		const orderTypeMap: Record<string, OrderType> = {
			peakup: OrderType.PICKUP,
		}

		const flagsMap: Record<Feature['name'], keyof Flags> = {
			requireLoginWallet: 'requireLogin',
			displayInCouponWallet: 'wallet',
		}

		const orderTypes: OrderType[] = rest.orderTypes
			.map((type) => (type in orderTypeMap ? orderTypeMap[type] : type))
			.filter((type) => (Object.values(OrderType) as string[]).includes(type)) as OrderType[]

		const flags: Partial<Flags> = features
			.map((x) => ({ ...x, name: x.name in flagsMap ? flagsMap[x.name] : x.name }))
			.reduce((acc, { name, ...fields }) => ({ ...acc, [name]: { value: true, ...fields } }), {} as Partial<Flags>)

		const expiration = coupon.expiration == null ? undefined : new Date(coupon.expiration)
		const formattedExpiration = expiration && Intl.DateTimeFormat(Infra?.locale?.msg?.replace('_', '-') ?? 'en-US').format(expiration)
		const attachedAt = 'attachedAt' in coupon && typeof coupon.attachedAt === 'number' ? new Date(coupon.attachedAt) : undefined

		const { code } = coupon

		return {
			...rest,
			...(attachedAt && { attachedAt }),
			code,
			orderTypes,
			flags,
			expiration,
			formattedExpiration,
			timestamp: new Date(),
		}
	}

	static async getMyCoupons(): Promise<StrippedCoupon[]> {
		const body: SearchBody = {
			fields: [
				{ name: 'relatedFeatures', operator: 'eq', value: 'displayInCouponWallet', logicalOperator: 'or' },
				{ name: 'relatedFeatures', operator: 'eq', value: 'showInMyCoupons', logicalOperator: 'or' },
			],
		}

		return (await this.fetch<ServerSideCoupon[]>('users/me/coupons/search', 'post', body))
			.map(this.normaliseCoupon)
			.map((coupon) => ({ ...coupon, flags: { ...coupon.flags, applied: { value: false } } }))
		// .map((coupon) => addVirtualFields(coupon))
	}

	static async getGT(menu: Menu): Promise<GetGTResponse | null> {
		const gt = await getGT(Cart.items, menu.items, false)
		return gt
	}

	static async getCoupon(code: string, checkCouponOn: CheckCouponOn): Promise<StrippedCoupon> {
		// TODO: Change this code to have a more abstracted implementation, for example, get the store ID from the parameters
		const storeId =
			checkCouponOn === CheckCouponOn.STORE && AddressManager.isUserLocalized() && localStorage.getItem('storeId')
				? localStorage.getItem('storeId')
				: null

		const queryParams: GetCouponQuery = {}

		if (storeId) {
			queryParams.storeId = storeId
		}

		return this.fetch<ServerSideCoupon | Record<never, never>>(`coupons/${code}`, 'get', {}, queryParams).then((coupon) => {
			if (this.isValidCoupon(coupon)) {
				// return addVirtualFields(this.normaliseCoupon(coupon))
				const result = this.normaliseCoupon(coupon)
				if (storeId) {
					// Disable caching on store coupons in order to prevent a bug where
					// you could fetch a store-specific coupon while unlocalised
					result.timestamp = new Date(0)
				}

				return result
			}

			throw new CouponError('Received invalid coupon from response', ErrorCode.DEFAULT)
		})
	}

	/**
	 * Adds a coupon to the current user's wallet
	 *
	 * @throws if the user already has this coupon, if the coupon doesn't exist
	 */
	static async addCouponToWallet(code: string): Promise<AddCouponToWalletResponse> {
		return this.fetch('coupons', 'post', { code })
	}

	static async getPrbCode(id: string): Promise<PrbCoupon> {
		console.count(`Fetching PRB code for ${id}`)
		return this.fetch<PrbResponse>(`coupons/${id}/GENERATE_QR`, 'patch')
			.then(({ couponCode, couponUrl }: PrbResponse): PrbCoupon => {
				if (!couponCode || !couponUrl || typeof couponUrl !== 'string' || typeof couponUrl !== 'string') {
					throw new CouponError('Error at PRB response', ErrorCode.USERS_COUPON_GENERATE_PRB_CODE)
				}

				return { code: couponCode, url: couponUrl }
			})
			.catch(() => {
				throw new CouponError('Error at PRB response', ErrorCode.USERS_COUPON_GENERATE_PRB_CODE)
			})
	}

	static getIgnoreOrderTypes(): OrderType[] {
		return (Infra?.appParams?.features?.disableCouponsOfOrderType as OrderType[]) ?? []
	}
}
